import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const TruncatedText = ({ text, charLimit = 300,title,name }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const truncatedText = text.length > charLimit ? text.slice(0, charLimit) + '[...]' : text;

  const handleReadMore = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <div>
      <p className="paragraph paragraph--white heading-padding">
        {truncatedText}
        {text.length > charLimit && (
          <button className='creative-heading-small read-more-btn creative-heading--yellow' onClick={handleReadMore}>
            Read more
          </button>
        )}
      </p>

      <Modal show={isModalOpen} onHide={handleClose} className='testimonial-modal' centered scrollable backdrop={true}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="testimonial-opopup">
                <h6 className="creative-heading-large creative-heading--yellow heading-padding">
                    {title}
                </h6>
                <p className="paragraph-small paragraph--white heading-padding">{text}</p>
                <div className="next-btn next-btn--yellow">
                    <div className="next-btn--bottom">
                    <span className="next-btn--bottom-line"></span>
                    <span className="next-btn--bottom-square"></span>
                    <span className="next-btn--bottom-line"></span>
                    </div>
                </div>
                <p className="paragraph-small paragraph--white">
                    {name}
                </p>
            </div>
            
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TruncatedText;