// helpers/instagram.js
export const fetchInstagramUserId = async (accessToken) => {
  try {
    const response = await fetch(`https://graph.instagram.com/me?fields=id,username&access_token=${accessToken}`);
    const data = await response.json();
    return data.id;
  } catch (error) {
    console.error('Error fetching Instagram user ID', error);
    return null;
  }
};