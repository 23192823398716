import Image from "next/image";

export default function Loading() {

    return (
      <>
       <section className="loading-section">
        <div className="d-flex flex-column">
        <Image  
          src="/logo-gif.svg"
          width={380}
          height={151}
          alt="Loading image"
          priority
          />
          <Image  
          src="/loading.gif"
          width={150}
          height={151}
          alt="Loading image"
          className="loading-gif"
          />
        </div>
       </section>
      </>
    );
  }
  