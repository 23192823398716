const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate(); // No ordinal suffix
  const month = date.toLocaleString('default', { month: 'long' }); // Full month name
  const year = date.getFullYear();
  return { day, month, year };
};

export const formatEventDate = (startDate, endDate) => {
  const start = formatDate(startDate);
  if (endDate) {
    const end = formatDate(endDate);
    if (start.month === end.month && start.year === end.year) {
      return `${start.day} - ${end.day} ${start.month} ${start.year}`;
    } else if (start.year === end.year) {
      return `${start.day} ${start.month} - ${end.day} ${end.month} ${start.year}`;
    } else {
      return `${start.day} ${start.month} ${start.year} - ${end.day} ${end.month} ${end.year}`;
    }
  } else {
    return `${start.day} ${start.month} ${start.year}`;
  }
};
